import React, { type FunctionComponent } from "react";
import { EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { message, Space, Switch, Table, Tooltip, Typography } from "antd";
import keyBy from "lodash.keyby";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { productActions } from "@fitness-app/app-store";
import {
  PriceStatus,
  ProductPriceAction,
  type ProductPrice,
  type StripePrice,
} from "@fitness-app/data-models/entities/Product";
import { getProductLink } from "@fitness-app/utils/src/products/getProductLink";

import DraggableTable from "~/components/DraggableTable/DraggableTable";
import EditPriceLabel from "~/modules/Products/Product/ProductPrices/EditPriceLabel";
import PriceConfig from "~/modules/Products/Product/ProductPrices/PriceConfig";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import EditCustomInvoiceName from "./EditCustomInvoiceName";

interface OwnProps {
  prices: ProductPrice[];
  productSlug: string;
  productId: string;
  productName: string;
  extended?: boolean;
  readonly?: boolean;
}

type Props = OwnProps;

type Price = ProductPrice;
const ProductPrices: FunctionComponent<Props> = ({ prices, productSlug, productId, extended = false, readonly }) => {
  const { t } = useTranslation("products");
  const dispatch = useAppDispatch();
  const productPricesActions = useAppSelector((store) => store.product.productPricesActions);
  const pricesWithoutArchived = prices.filter((price) => price.status !== PriceStatus.Archived);

  const handlePricesOrderUpdate = (draggabledId: string, destinationIndex: number, sourceIndex: number) => {
    const pricesIds = prices.map((p) => p.id);
    if (!pricesIds.includes(draggabledId)) {
      return;
    }

    pricesIds.splice(sourceIndex, 1);
    pricesIds.splice(destinationIndex, 0, draggabledId);
    const pricesById = keyBy(prices, "id");

    const updatedPrices = pricesIds.map((id) => pricesById[id]).filter((price): price is StripePrice => !!price);

    void dispatch(
      productActions.updateProductPricesOrder({
        productId,
        prices: updatedPrices,
      }),
    );
  };

  return (
    <div className="my-6">
      <DraggableTable<ProductPrice>
        onUpdateListOrder={handlePricesOrderUpdate}
        rowKey="id"
        dndDisabled={!extended || readonly}
        dataSource={pricesWithoutArchived}
        scroll={{ x: true }}
      >
        <Table.Column<Price> width={50} dataIndex="sort" key="sort" />
        <Table.Column<Price>
          title={t("list.columns.index")}
          width={50}
          dataIndex="index"
          key="index"
          render={(_, __, index) => index + 1}
        />
        <Table.Column<Price>
          title="ID"
          dataIndex="id"
          key="id"
          render={(id: string) => (
            <Typography.Paragraph
              copyable={{ tooltips: [id, t("common:copied")], text: id }}
              style={{ marginBottom: 0 }}
            >
              {truncate(id, { length: 10 })}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<Price>
          title={t("list.columns.price")}
          dataIndex="price"
          key="price"
          render={(_, price) => {
            if (!price.unit_amount) {
              return <span>-</span>;
            }

            if (extended && price.config?.showAsPromo) {
              return (
                <Space direction="horizontal">
                  <Typography.Paragraph type="danger" style={{ marginBottom: 0 }}>
                    {price.currency === "pln"
                      ? String(price.unit_amount / 100).replace(".", ",")
                      : price.unit_amount / 100}{" "}
                    {price.currency.toUpperCase()}
                  </Typography.Paragraph>
                  <Typography.Paragraph delete style={{ marginBottom: 0 }}>
                    {price.currency === "pln"
                      ? String(price.config.showAsPromo.priceBeforeDiscount / 100).replace(".", ",")
                      : price.config.showAsPromo.priceBeforeDiscount / 100}{" "}
                    {price.currency.toUpperCase()}
                  </Typography.Paragraph>
                </Space>
              );
            }

            return (
              <span>
                {price.currency === "pln" ? String(price.unit_amount / 100).replace(".", ",") : price.unit_amount / 100}{" "}
                {price.currency.toUpperCase()}
              </span>
            );
          }}
        />
        <Table.Column<Price>
          title={t("list.columns.paymentType")}
          dataIndex="priceType"
          key="priceType"
          render={(_, price: Price) => {
            const { type, accessPeriod, id, customLabel, customInvoiceName } = price;

            return (
              <div>
                <span>
                  {t(`paymentTypeOptions.${type}`)}
                  {type === "recurring" || (type === "one_time" && accessPeriod)
                    ? `(${t(`${type}.${accessPeriod?.interval}`, {
                        count: accessPeriod?.interval_count,
                      })})`
                    : ""}
                  {price.accessPeriod?.type === "one_time" && price.accessPeriod.endAccessStrategy ? (
                    <Tooltip
                      title={
                        <span>
                          {t("products:form.endAccessStrategy")}
                          {": "}
                          {t(`common:endAccessStrategy.${price.accessPeriod.endAccessStrategy}`)}
                        </span>
                      }
                    >
                      <InfoCircleOutlined style={{ marginLeft: 10, color: "#ffa800" }} />
                    </Tooltip>
                  ) : null}
                </span>
                {price.installments && (
                  <div>
                    {t("paymentTypeOptions.installments")}
                    {price.installments}
                  </div>
                )}
                {extended && (
                  <>
                    <div className="flex flex-col">
                      <EditPriceLabel
                        id={id}
                        label={customLabel}
                        productId={productId}
                        type={type}
                        accessPeriod={accessPeriod}
                      />
                    </div>
                    <div className="mt-5 flex">
                      <EditCustomInvoiceName id={id} label={customInvoiceName} productId={productId} />
                    </div>
                  </>
                )}
              </div>
            );
          }}
        />
        <Table.Column<Price>
          title={t("list.columns.totalClients")}
          dataIndex="clients"
          key="clients"
          render={(clients) => <span>{clients ?? 0}</span>}
        />
        <Table.Column<Price>
          title={t("list.columns.quantityLimit")}
          dataIndex="quantity"
          key="quantity"
          render={(_, price) => {
            const limit = price.config?.quantityLimit;
            const available = Number(limit) - Number(price.clients);
            if (limit) {
              return (
                <Typography.Text type={available === 0 ? "danger" : undefined}>
                  {available ?? "-"}
                  {" / "}
                  {limit ?? "-"}
                </Typography.Text>
              );
            }
            return <span>-</span>;
          }}
        />
        <Table.Column<Price>
          title={t("list.columns.link")}
          dataIndex="link"
          key="link"
          render={(_, row) => (
            <Tooltip title={t("list.copyToClipboard")}>
              <LinkOutlined
                style={{ fontSize: 22 }}
                onClick={() =>
                  navigator.clipboard.writeText(
                    getProductLink({
                      productId,
                      domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
                      slug: productSlug,
                      ...(row.hidden ? { checkoutId: row.checkoutId } : { priceId: row.id }),
                    }),
                  )
                }
              />
            </Tooltip>
          )}
        />
        <Table.Column<Price>
          title={t("list.columns.active")}
          dataIndex="active"
          key="active"
          render={(active: boolean, row) => {
            const currentlyProcessed = productPricesActions
              ? productPricesActions.find((item) => item.id === row.id)
              : null;
            return (
              <Switch
                checked={active}
                disabled={readonly}
                onChange={() =>
                  dispatch(
                    productActions.updateProductPrices({
                      onFailure: () => {
                        void message.error(t("errorWhenUpdatingPrice"));
                      },
                      payload: {
                        prices: [
                          {
                            active: !active,
                            productId,
                            priceId: row.id,
                            action: ProductPriceAction.ToggleStatus,
                          },
                        ],
                      },
                    }),
                  )
                }
                loading={currentlyProcessed ? currentlyProcessed.action === ProductPriceAction.ToggleStatus : false}
              />
            );
          }}
        />
        {extended ? (
          <Table.Column<Price>
            title={
              <div>
                {t("list.columns.hidden")}{" "}
                <Tooltip title={t("list.columns.hiddenInfo")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            dataIndex="hidden"
            key="hidden"
            render={(hidden: boolean, row) => {
              const currentlyProcessed = productPricesActions
                ? productPricesActions.find((item) => item.id === row.id)
                : null;
              return (
                <Switch
                  checked={hidden}
                  disabled={readonly}
                  checkedChildren={<EyeInvisibleOutlined />}
                  unCheckedChildren={<EyeOutlined />}
                  onChange={() =>
                    dispatch(
                      productActions.updateProductPrices({
                        onFailure: () => {
                          void message.error(t("errorWhenUpdatingPrice"));
                        },
                        payload: {
                          prices: [
                            {
                              hidden: !hidden,
                              productId,
                              priceId: row.id,
                              checkoutId: row.checkoutId || uuid(),
                              action: ProductPriceAction.ToggleVisibility,
                            },
                          ],
                        },
                      }),
                    )
                  }
                  loading={
                    currentlyProcessed ? currentlyProcessed.action === ProductPriceAction.ToggleVisibility : false
                  }
                />
              );
            }}
          />
        ) : (
          <></>
        )}
        {extended && !readonly ? (
          <Table.Column<Price>
            title={t("list.columns.options")}
            key="action"
            align="left"
            render={(_, row) => (
              <PriceConfig
                price={row}
                productId={productId}
                extended={extended}
                canRemove={pricesWithoutArchived.length > 1}
                productPricesActions={productPricesActions}
              />
            )}
          />
        ) : (
          <></>
        )}
      </DraggableTable>
    </div>
  );
};

export default ProductPrices;
