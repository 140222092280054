import React, { useEffect, useState } from "react";
import {
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileOutlined,
  PlusOutlined,
  PushpinOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Empty,
  Popconfirm,
  Spin,
  Switch,
  Tag,
  Timeline,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import dayjs from "dayjs";
import orderBy from "lodash.orderby";
import { useTranslation } from "react-i18next";

import { RequestStatus } from "@fitness-app/app-store";
import { clientNotesActions } from "@fitness-app/app-store/src/store/reducers/traineeNotes";
import { NoteAction } from "@fitness-app/app-store/src/store/reducers/traineeNotes/types";
import { UserRole } from "@fitness-app/data-models";
import { AuthorTypeEnum } from "@fitness-app/data-models/entities/AuthorTypeEnum";
import {
  ClientNoteCategory,
  ClientNoteType,
  type ClientNote,
  type ClientNoteWithAuthorData,
} from "@fitness-app/data-models/entities/ClientNote";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";
import { getUserInitials } from "@fitness-app/utils";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { type ClientNoteFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import ClientNoteForm from "./ClientNoteForm";

export const mapCategoryColor = {
  [ClientNoteCategory.General]: undefined,
  [ClientNoteCategory.Training]: "blue",
  [ClientNoteCategory.Nutrition]: "gold",
  [ClientNoteCategory.Health]: "red",
  [ClientNoteCategory.Management]: "green",
};

const TraineeNotes = () => {
  const { t } = useTranslation("trainees");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [noteModel, setModel] = useState<ClientNote | null>(null);
  const traineeId = useAppSelector((store) => store.trainee.profile?.id);
  const { dataStatus, noteAction, data } = useAppSelector((store) => store.traineeNotes);
  const dispatch = useAppDispatch();
  const { role, userId } = useUserRole();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (traineeId) {
      void dispatch(clientNotesActions.fetchClientNotes({ traineeId }));
    }
  }, [traineeId]);

  useEffect(() => {
    return () => {
      dispatch(clientNotesActions.unsubscribeFromClientNotes());
    };
  }, []);
  const handleSubmit = async (formData: ClientNoteFormModel) => {
    if (noteModel && traineeId) {
      await dispatch(
        clientNotesActions.editClientNote({
          note: formData,
          traineeId,
          noteId: noteModel.id,
          authorId: noteModel.authorId,
        }),
      );
      setIsFormVisible(false);
    } else if (traineeId) {
      const model: ClientNote = {
        attachments: formData.attachments || [],
        id: generateUniqId(),
        traineeId,
        content: formData.content,
        type: formData.type || ClientNoteType.Text,
        category: formData.category || ClientNoteCategory.General,
        showInDashboard: formData.showInDashboard ?? false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        pinned: false,
        authorType: role === UserRole.TRAINER ? AuthorTypeEnum.Admin : AuthorTypeEnum.Trainer,
        authorId: userId,
      };

      await dispatch(clientNotesActions.addClientNote({ note: model }));
      setIsFormVisible(false);
    }

    void queryClient.invalidateQueries(["nutritionNotes", traineeId]);
    void queryClient.invalidateQueries(["traineeDashboardNote", traineeId]);
  };

  const editNote = (note: ClientNote) => {
    setModel(note);
    setIsFormVisible(true);
  };

  const toggleIsPinnedNote = async (note: ClientNote) => {
    if (traineeId) {
      await dispatch(
        clientNotesActions.editClientNote({
          note: { pinned: !note.pinned },
          traineeId,
          authorId: note.authorId,
          noteId: note.id,
        }),
      );
      void queryClient.invalidateQueries(["nutritionNotes", traineeId]);
    }
  };

  const onVisibilityChange = async (showInDashboard: boolean, note: ClientNote) => {
    if (traineeId) {
      await dispatch(
        clientNotesActions.editClientNote({
          note: { showInDashboard },
          traineeId,
          authorId: note.authorId,
          noteId: note.id,
        }),
      );
      void queryClient.invalidateQueries(["nutritionNotes", traineeId]);
      void queryClient.invalidateQueries(["traineeDashboardNote", traineeId]);
    }
  };

  const removeNote = (id: string) => {
    if (traineeId) {
      void dispatch(clientNotesActions.removeClientNote({ noteId: id, traineeId }));
      void queryClient.invalidateQueries(["nutritionNotes", traineeId]);
      void queryClient.invalidateQueries(["traineeDashboardNote", traineeId]);
    }
  };

  const createTimelineItem = (item: ClientNoteWithAuthorData) => {
    if (item.type === ClientNoteType.Event) {
      return (
        <Timeline.Item
          key={item.id}
          position="left"
          dot={<ThunderboltOutlined style={{ color: "black", fontSize: "16px" }} />}
        >
          <Card title={t("students:timeline.items.lifeEvent")} size="small">
            <p>{item.type}</p>
            <p className="whitespace-pre-wrap">{item.content}</p>
            <Typography.Text type="secondary">
              {`${t("students:timeline.date")}: ${dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}`}
            </Typography.Text>
          </Card>
        </Timeline.Item>
      );
    }

    return (
      <Timeline.Item key={item.id} position="left" dot={<FileOutlined style={{ color: "black", fontSize: "16px" }} />}>
        <Card
          title={
            <div className="flex flex-col gap-y-2 py-1">
              <div className="flex gap-x-2">
                <Tooltip title={item.author ? getAuthorName(item.author) : t("notes.authorDeleted")}>
                  <Avatar size="small" src={item.author?.avatarUrl}>
                    {item.author ? getUserInitials(item.author) : t("notes.authorDeleted")}
                  </Avatar>
                </Tooltip>
                <Typography.Text>Notatka tekstowa</Typography.Text>
              </div>

              <div className="flex items-baseline gap-x-2">
                <Typography.Text type="secondary" className="text-sm">
                  <ClockCircleOutlined style={{ marginRight: 8, marginBottom: 10 }} />
                  {item.createdAt ? dayjs(item.createdAt).format("DD.MM.YYYY HH:mm") : ""}
                </Typography.Text>

                <Tag color={mapCategoryColor[item.category]}>
                  {t(`notes.categories.${item.category || ClientNoteCategory.General}`)}
                </Tag>
              </div>
            </div>
          }
          className={item.pinned ? "border border-yellow-500" : ""}
          extra={
            <div className="flex items-center gap-2">
              <Switch
                value={item.showInDashboard}
                onChange={(checked) => onVisibilityChange(checked, item)}
                checkedChildren={<EyeOutlined />}
                unCheckedChildren={<EyeInvisibleOutlined />}
              />
              <Divider type="vertical" />

              <Button onClick={() => toggleIsPinnedNote(item)}>
                <PushpinOutlined />
              </Button>

              {item.authorId === userId && (
                <>
                  <Divider type="vertical" />

                  <Button onClick={() => editNote(item)}>
                    <EditOutlined />
                  </Button>
                </>
              )}

              {item.authorId === userId || role === UserRole.TRAINER ? (
                <>
                  <Divider type="vertical" />

                  <Popconfirm
                    title={t("notes.deleteTitle")}
                    okText={t("common:yes")}
                    cancelText={t("common:no")}
                    placement="left"
                    onConfirm={() => removeNote(item.id)}
                  >
                    <Button danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </>
              ) : null}
            </div>
          }
        >
          <p className="whitespace-pre-wrap">{item.content}</p>

          {item.attachments?.length ? <Upload fileList={item.attachments} disabled /> : null}
        </Card>
      </Timeline.Item>
    );
  };

  return (
    <Card
      bordered={false}
      className="min-h-full !shadow-none"
      loading={dataStatus === RequestStatus.FETCHING}
      extra={
        data.length ? (
          <Button size="small" icon={<PlusOutlined />} type="primary" onClick={() => setIsFormVisible(true)}>
            Dodaj notatkę
          </Button>
        ) : null
      }
    >
      {!data?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginTop: 80 }}
          description={
            <div>
              <p style={{ marginBottom: 20 }}>Brak zapisanych notatek</p>
              <Button size="small" icon={<PlusOutlined />} type="primary" onClick={() => setIsFormVisible(true)}>
                Dodaj pierwszą notatkę
              </Button>
            </div>
          }
        />
      ) : (
        <Timeline mode="left">{orderBy(data, ["pinned"], ["desc"]).map(createTimelineItem)}</Timeline>
      )}
      <ModalForm
        open={isFormVisible}
        loading={noteAction === NoteAction.Adding || noteAction === NoteAction.Updating}
        onCancel={() => {
          setIsFormVisible(false);
          setModel(null);
        }}
        title={noteModel ? t("notes.editFormTitle") : t("notes.formTitle")}
      >
        {isFormVisible ? (
          <ClientNoteForm onSubmit={handleSubmit} model={noteModel} traineeId={traineeId || "default"} />
        ) : (
          <Spin />
        )}
      </ModalForm>
    </Card>
  );
};

export default TraineeNotes;
