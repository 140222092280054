import React, { useEffect, useMemo } from "react";
import { Avatar, Form, Input, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { teamSelectors } from "@fitness-app/app-store";
import { TeamMemberRole, TeamMemberStatus } from "@fitness-app/data-models";
import { getUserInitials } from "@fitness-app/utils";

import { useTagOptions } from "~/hooks/useTagOptions";
import { useAppSelector } from "~/store/initializeStore";
import { type TeamTrainerFormModel } from "./types";

interface TeamTrainerFormProps {
  formController?: FormInstance<TeamTrainerFormModel>;
  onSubmit: (formData: TeamTrainerFormModel) => void;
  model?: Partial<TeamTrainerFormModel> | null;
}

const TeamTrainerForm = ({ model, onSubmit, formController }: TeamTrainerFormProps) => {
  const { t } = useTranslation(["team", "common"]);
  const { options: tagsOptions } = useTagOptions("specializationTags");
  const selectedRole = Form.useWatch("role", formController);
  const onlyNotAssignedTrainers = Form.useWatch("showOnlyNotAssigned", formController);
  const trainers = useAppSelector(teamSelectors.getTrainersTeam);
  const assignedTrainers = useAppSelector(teamSelectors.getAssignedRegularTrainers);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model, formController]);

  const editMode = Boolean(model);

  const currentTrainerId = model?.id;

  const trainerOptions = useMemo(() => {
    const byId = keyBy(trainers, "id");

    const getAssignedTo = (trainerId: string) =>
      Object.keys(assignedTrainers).find(
        (assignedTrainerId) => assignedTrainers[assignedTrainerId]?.includes(trainerId),
      );

    const list = trainers
      .filter(
        (trainer) =>
          trainer.role === TeamMemberRole.REGULAR_TRAINER &&
          [TeamMemberStatus.ACTIVE, TeamMemberStatus.INVITED].includes(trainer.status),
      )
      .map((trainer) => {
        const assignedTo = getAssignedTo(trainer.id);
        const assignedTrainerData = assignedTo ? byId[assignedTo] : null;

        return {
          label: `${trainer.firstName} ${trainer.lastName}`,
          value: trainer.id,
          avatarUrl: trainer.user?.avatarUrl || null,
          assignedTo: assignedTrainerData
            ? `${assignedTrainerData.firstName.slice(0, 1)}. ${assignedTrainerData.lastName}`
            : null,
        };
      });

    if (onlyNotAssignedTrainers) {
      const withoutCurrentTrainer = {
        ...assignedTrainers,
        [currentTrainerId || "none"]: [],
      };

      const assignedTrainersIds = Object.values(withoutCurrentTrainer).flat();

      return list.filter((trainer) => !assignedTrainersIds.includes(trainer.value));
    }

    return list;
  }, [trainers, assignedTrainers, onlyNotAssignedTrainers, currentTrainerId]);

  return (
    <Form<TeamTrainerFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        status: true,
        role: null,
        specializationTags: [],
        showOnlyNotAssigned: true,
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="firstName"
        label={t<string>("common.firstName")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input disabled={editMode} />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t<string>("common.lastName")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input disabled={editMode} />
      </Form.Item>
      <Form.Item
        name="email"
        label={t<string>("common.email")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            type: "email",
            message: t<string>("common:validationErrors.wrongEmail"),
          },
        ]}
      >
        <Input disabled={editMode} />
      </Form.Item>

      <Form.Item
        name="role"
        label={t<string>("common.role")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          placeholder={t<string>("selectRole")}
          options={[
            {
              label: t("role.regular_trainer"),
              value: TeamMemberRole.REGULAR_TRAINER,
            },
            {
              label: t("role.shared_trainer"),
              value: TeamMemberRole.SHARED_TRAINER,
            },
          ]}
        />
      </Form.Item>

      <Form.Item name="specializationTags" label={t<string>("membersSpecializations")}>
        <Select notFoundContent={false} options={tagsOptions} mode="tags" />
      </Form.Item>

      <Form.Item name="status" label={t<string>("common.active")} valuePropName="checked">
        <Switch disabled={!model} />
      </Form.Item>

      {selectedRole === TeamMemberRole.SHARED_TRAINER && (
        <div>
          <Form.Item name="showOnlyNotAssigned" label={t<string>("common.showOnlyNotAssigned")} valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item name="assignedTrainers" label={t<string>("common.assignedTrainers")}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              mode="multiple"
              placeholder={t("common.selectTrainers")}
            >
              {trainerOptions.map((trainer) => (
                <Select.Option key={trainer.value} value={trainer.value} title={trainer.label} label={trainer.label}>
                  <Avatar src={trainer.avatarUrl}>{getUserInitials(trainer.label)}</Avatar> {trainer.label}{" "}
                  {trainer.assignedTo ? `(${trainer.assignedTo})` : ""}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
    </Form>
  );
};

export default TeamTrainerForm;
