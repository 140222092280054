import { type AutomationTaskWithoutTriggers } from "../../../entities/Automation";
import {
  type Currency,
  type PricePaymentType,
  type ProductChargeTypeEnum,
  type ProductSubscriptionInterval,
  type ProductTypeEnum,
} from "../../../entities/Product";
import { type ClientArchiveReason } from "../../../entities/ProductClient";
import { type GenericBusinessEventDto } from "../../BusinessEventDto";
import { type GenericBusinessEvent } from "../BusinessEventEnvelope";

export enum ProductEventType {
  ProductPurchased = "fitness-app.product.product-purchased",
  ProductCreated = "fitness-app.product.product-created",
  ProductAccessRescheduled = "fitness-app.product.product-access-rescheduled",
  ProductSubscriptionRenewed = "fitness-app.product.product-subscription-renewed",
  ProductSubscriptionPlanUpdated = "fitness-app.product.product-subscription-plan-updated",
  ProductAccessRenewed = "fitness-app.product.product-access-renewed",
  ProductSubscriptionEnd = "fitness-app.product.product-subscription-end",
  ProductAccessPeriodEnd = "fitness-app.product.product-access-period-end",
  ProductClientImported = "fitness-app.product.client-imported",
  ProductClientsImportFinished = "fitness-app.product.clients-import-finished",
  ProductSubscriptionCancelAtPeriodEnd = "fitness-app.product.product-subscription-cancel-at-period-end",
  ProductSubscriptionResumeAtPeriodEnd = "fitness-app.product.product-subscription-resume-at-period-end",
  ProductAccessPeriodEndingIn14Days = "fitness-app.product.product-access-period-ending-in-14-days",
  ProductAccessPeriodEndingIn7Days = "fitness-app.product.product-access-period-ending-in-7-days",
  ProductAccessPeriodEndingIn3Days = "fitness-app.product.product-access-period-ending-in-3-days",
  ProductAccessPeriodEndingIn1Day = "fitness-app.product.product-access-period-ending-in-1-day",
  ProductClientArchived = "fitness-app.product.product-client-archived",
  ProductClientEmailChanged = "fitness-app.product.product-client-email-changed",
  ProductSlugChanged = "fitness-app.product.product-slug-changed",
  ProductClientRemoved = "fitness-app.product.product-client-removed",
}

export type ProductPurchasedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductPurchased,
  {
    productId: string;
    clientEmail: string;
    priceId: string | null;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    ownerEmail: string;
    isFree: boolean;
    orderId: string;
    paymentType: PricePaymentType | null;
    discounted?: boolean;
    ownerId: string;
    price: null | { amount: number; currency: Currency };
    refCode?: string | null;
    productType: ProductTypeEnum;
    clientRefCode?: string | null;
    productSlug: string | null;
    sessionId: string | null;
  }
>;

export type ProductSubscriptionRenewedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductSubscriptionRenewed,
  {
    productId: string;
    clientEmail: string;
    priceId: string;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    ownerEmail: string;
    discounted?: boolean;
    orderId: string;
    paymentType: "recurring";
    ownerId: string;
    price: { amount: number; currency: Currency };
  }
>;

export type ProductSubscriptionPlanUpdatedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductSubscriptionPlanUpdated,
  {
    productId: string;
    clientEmail: string;
    priceId: string;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    ownerEmail: string;
    discounted?: boolean;
    orderId: string;
    paymentType: "recurring";
    ownerId: string;
    price: {
      amount: number;
      currency: Currency;
      baseAmount: number;
      interval_count: number;
      interval: ProductSubscriptionInterval;
    };
    prorated: boolean;
    previousPrice: {
      id: string;
      amount: number;
      currency: Currency;
      interval_count: number;
      interval: ProductSubscriptionInterval;
    };
  }
>;

export type ProductAccessRenewedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductAccessRenewed,
  {
    productId: string;
    clientEmail: string;
    priceId: string;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    discounted?: boolean;
    ownerEmail: string;
    orderId: string;
    paymentType: "one_time" | "installments";
    ownerId: string;
    price: { amount: number; currency: Currency };
    installments?: number | null;
  }
>;

export type ProductSubscriptionEndEventDto = GenericBusinessEventDto<
  ProductEventType.ProductSubscriptionEnd,
  {
    productId: string;
    clientEmail: string;
    priceId: string | null;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    ownerEmail: string;
    ownerId: string;
    price: { amount: number; currency: Currency } | null;
  }
>;

export type ProductAccessPeriodEndEventDto = GenericBusinessEventDto<
  ProductEventType.ProductAccessPeriodEnd,
  {
    productId: string;
    clientEmail: string;
    priceId: string | null;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    ownerEmail: string;
    ownerId: string;
    price: { amount: number; currency: Currency } | null;
    reason?: ClientArchiveReason;
  }
>;

export type ProductCreatedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductCreated,
  {
    productId: string;
    productName: string;
    productChargeType: ProductChargeTypeEnum;
    ownerId: string;
  }
>;

export type ProductClientImportedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductClientImported,
  {
    productId: string;
    clientEmail: string;
    priceId: string | null;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    ownerEmail: string;
    isFree: boolean;
    orderId: string;
    importId: string;
    paymentType: PricePaymentType | null;
    productChargeType: ProductChargeTypeEnum;
    ownerId: string;
    price: null | { amount: number; currency: Currency };
    silent: boolean;
    clientRefCode?: string | null;
    createdClientId?: string | null;
  }
>;

export type ProductClientsImportFinishedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductClientsImportFinished,
  {
    productId: string;
    productName: string;
    ownerId: string;
    importId: string;
    ownerEmail: string;
    totalClients: number;
    failedImports: number;
    isFree: boolean;
  }
>;

export type ProductClientArchivedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductClientArchived,
  {
    productId: string;
    ownerId: string;
    ownerEmail: string;
    isFree: boolean;
    priceId: string | null;
    productName: string;
    clientEmail: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    refunded: boolean;
    refund: { amount: number; currency: Currency } | null;
    tasks: AutomationTaskWithoutTriggers[];
  }
>;

export type ProductClientEmailChangedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductClientEmailChanged,
  {
    productId: string;
    ownerId: string;
    ownerEmail: string;
    isFree: boolean;
    priceId: string | null;
    productName: string;
    clientEmail: string;
    clientFirstName: string;
    clientLastName: string;
    clientPhoneNumber: string | null;
    oldEmail: string;
    tasksForOldEmail: AutomationTaskWithoutTriggers[];
    tasksForNewEmail: AutomationTaskWithoutTriggers[];
    userId: string | null;
  }
>;

export type ProductSubscriptionCancelAtPeriodEndEventDto = GenericBusinessEventDto<
  ProductEventType.ProductSubscriptionCancelAtPeriodEnd,
  {
    productId: string;
    clientEmail: string;
    priceId: string;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    ownerEmail: string;
    paymentType: "recurring";
    ownerId: string;
    price: { amount: number; currency: Currency };
    periodEnd: number;
  }
>;

export type ProductSubscriptionResumeAtPeriodEndEventDto = GenericBusinessEventDto<
  ProductEventType.ProductSubscriptionResumeAtPeriodEnd,
  {
    productId: string;
    clientEmail: string;
    priceId: string;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    ownerEmail: string;
    paymentType: "recurring";
    ownerId: string;
    price: { amount: number; currency: Currency };
    periodEnd: number;
  }
>;

export type ProductAccessPeriodEndingEventDto = GenericBusinessEventDto<
  | ProductEventType.ProductAccessPeriodEndingIn14Days
  | ProductEventType.ProductAccessPeriodEndingIn7Days
  | ProductEventType.ProductAccessPeriodEndingIn3Days
  | ProductEventType.ProductAccessPeriodEndingIn1Day,
  {
    productId: string;
    clientEmail: string;
    priceId: string | null;
    productName: string;
    clientFirstName: string;
    clientLastName: string;
    ownerEmail: string;
    ownerId: string;
    endAt: number;
    price: { amount: number; currency: Currency } | null;
    checkoutUrl: string;
  }
>;

export type ProductSlugChangedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductSlugChanged,
  {
    productId: string;
    productName: string;
    ownerEmail: string;
    isFree: boolean;
    ownerId: string;
    slug: string;
    oldSlug: string | null;
  }
>;

export type ProductClientRemovedEventDto = GenericBusinessEventDto<
  ProductEventType.ProductClientRemoved,
  {
    productId: string;
    ownerId: string;
    ownerEmail: string;
    isFree: boolean;
    priceId: string | null;
    productName: string;
    clientEmail: string;
    clientFirstName: string;
    clientLastName: string;
    tasks?: AutomationTaskWithoutTriggers[] | null;
  }
>;

export type ProductAccessRescheduledEventDto = GenericBusinessEventDto<
  ProductEventType.ProductAccessRescheduled,
  {
    productId: string;
    clientEmail: string;
    productName: string;
    ownerId: string;
    productClientId: string;
    currentEndAtAccess: number | null;
    currentAssignedProductClientId: string | null;
    currentAssignedProductId: string | null;
  }
>;

export type ProductPurchasedEvent = GenericBusinessEvent<
  ProductPurchasedEventDto["eventType"],
  ProductPurchasedEventDto["payload"]
>;

export type ProductCreatedEvent = GenericBusinessEvent<
  ProductCreatedEventDto["eventType"],
  ProductCreatedEventDto["payload"]
>;

export type ProductSubscriptionRenewedEvent = GenericBusinessEvent<
  ProductSubscriptionRenewedEventDto["eventType"],
  ProductSubscriptionRenewedEventDto["payload"]
>;

export type ProductSubscriptionPlanUpdatedEvent = GenericBusinessEvent<
  ProductSubscriptionPlanUpdatedEventDto["eventType"],
  ProductSubscriptionPlanUpdatedEventDto["payload"]
>;

export type ProductAccessRenewedEvent = GenericBusinessEvent<
  ProductAccessRenewedEventDto["eventType"],
  ProductAccessRenewedEventDto["payload"]
>;

export type ProductSubscriptionEndEvent = GenericBusinessEvent<
  ProductSubscriptionEndEventDto["eventType"],
  ProductSubscriptionEndEventDto["payload"]
>;

export type ProductAccessPeriodEndEvent = GenericBusinessEvent<
  ProductAccessPeriodEndEventDto["eventType"],
  ProductAccessPeriodEndEventDto["payload"]
>;

export type ProductClientImportedEvent = GenericBusinessEvent<
  ProductClientImportedEventDto["eventType"],
  ProductClientImportedEventDto["payload"]
>;

export type ProductClientsImportFinishedEvent = GenericBusinessEvent<
  ProductClientsImportFinishedEventDto["eventType"],
  ProductClientsImportFinishedEventDto["payload"]
>;

export type ProductClientArchivedEvent = GenericBusinessEvent<
  ProductClientArchivedEventDto["eventType"],
  ProductClientArchivedEventDto["payload"]
>;

export type ProductClientEmailChangedEvent = GenericBusinessEvent<
  ProductClientEmailChangedEventDto["eventType"],
  ProductClientEmailChangedEventDto["payload"]
>;

export type ProductSubscriptionCancelAtPeriodEndEvent = GenericBusinessEvent<
  ProductSubscriptionCancelAtPeriodEndEventDto["eventType"],
  ProductSubscriptionCancelAtPeriodEndEventDto["payload"]
>;

export type ProductSubscriptionResumeAtPeriodEndEvent = GenericBusinessEvent<
  ProductSubscriptionResumeAtPeriodEndEventDto["eventType"],
  ProductSubscriptionResumeAtPeriodEndEventDto["payload"]
>;

export type ProductAccessPeriodEndingEvent = GenericBusinessEvent<
  ProductAccessPeriodEndingEventDto["eventType"],
  ProductAccessPeriodEndingEventDto["payload"]
>;

export type ProductSlugChangedEvent = GenericBusinessEvent<
  ProductSlugChangedEventDto["eventType"],
  ProductSlugChangedEventDto["payload"]
>;

export type ProductClientRemovedEvent = GenericBusinessEvent<
  ProductClientRemovedEventDto["eventType"],
  ProductClientRemovedEventDto["payload"]
>;

export type ProductAccessRescheduledEvent = GenericBusinessEvent<
  ProductAccessRescheduledEventDto["eventType"],
  ProductAccessRescheduledEventDto["payload"]
>;

export type ProductEventsDto =
  | ProductPurchasedEventDto
  | ProductCreatedEventDto
  | ProductSubscriptionRenewedEventDto
  | ProductSubscriptionEndEventDto
  | ProductAccessPeriodEndEventDto
  | ProductClientImportedEventDto
  | ProductClientsImportFinishedEventDto
  | ProductSubscriptionCancelAtPeriodEndEventDto
  | ProductSubscriptionResumeAtPeriodEndEventDto
  | ProductAccessPeriodEndingEventDto
  | ProductAccessRenewedEventDto
  | ProductClientArchivedEventDto
  | ProductSubscriptionPlanUpdatedEventDto
  | ProductClientEmailChangedEventDto
  | ProductClientRemovedEventDto
  | ProductSlugChangedEventDto
  | ProductAccessRescheduledEventDto;

export type ProductEvents =
  | ProductPurchasedEvent
  | ProductCreatedEvent
  | ProductSubscriptionRenewedEvent
  | ProductSubscriptionEndEvent
  | ProductAccessPeriodEndEvent
  | ProductClientImportedEvent
  | ProductClientsImportFinishedEvent
  | ProductSubscriptionCancelAtPeriodEndEvent
  | ProductSubscriptionResumeAtPeriodEndEvent
  | ProductAccessPeriodEndingEvent
  | ProductAccessRenewedEvent
  | ProductSubscriptionPlanUpdatedEvent
  | ProductClientArchivedEvent
  | ProductClientEmailChangedEvent
  | ProductSlugChangedEvent
  | ProductClientRemovedEvent
  | ProductAccessRescheduledEvent;

export type ProductEventsPayload =
  | ProductPurchasedEventDto["payload"]
  | ProductCreatedEvent["payload"]
  | ProductSubscriptionRenewedEvent["payload"]
  | ProductSubscriptionEndEvent["payload"]
  | ProductAccessPeriodEndEvent["payload"]
  | ProductClientImportedEvent["payload"]
  | ProductClientsImportFinishedEvent["payload"]
  | ProductSubscriptionCancelAtPeriodEndEvent["payload"]
  | ProductSubscriptionResumeAtPeriodEndEvent["payload"]
  | ProductAccessPeriodEndingEvent["payload"]
  | ProductAccessRenewedEvent["payload"]
  | ProductSubscriptionPlanUpdatedEvent["payload"]
  | ProductClientArchivedEvent["payload"]
  | ProductClientEmailChangedEvent["payload"]
  | ProductSlugChangedEvent["payload"]
  | ProductClientRemovedEvent["payload"]
  | ProductAccessRescheduledEvent["payload"];
